/*
 * !!!! Warning !!!!
 *
 * This component is used for both public and non-public versions of the
 * local pros page. Changes will impact both, considerations must be made
 * with both versions of this page in mind!
 */

/**
 * @typedef {import ('./MarketplaceProps').default} Props
 * @typedef {import ('./MarketplaceState').default} State
 * @typedef {import ('./Offer').default} Offer
 * @typedef {import ('./Property').default} Property
 * @typedef {import('../shared/MarketplaceService/Vendor').default} Vendor
 * @typedef {import('../shared/MovingTask').default} MovingTask
 */
import { AuthContext } from '../shared/AuthProvider';
import { translate } from '../App/Internationalization';
import { withHomeownerService } from '../service-container';
import { withTheme } from '../shared/ThemeProvider';
import * as taskService from '../shared/task-service';
import BaseHero from '../shared/BaseHero';
import captureError from '../utils/captureError';
import images from '../config/local/images';
import Loading from '../shared/Loading';
import MarketplaceSearchForm from './MarketplaceSearchForm';
import Metadata from '../shared/Metadata';
import NoContent from '../shared/NoContent';
import OfferCard from './OfferCard';
import Pagination from '../shared/Pagination';
import paths from '../config/local/paths';
import React from 'react';
import { getCategoryLabel } from './helpers';
import { occupation } from '../shared/ThemeProvider/theme-utilities';

/** @extends {React.Component<Props, State>} */
export class Marketplace extends React.Component {
	/** @param {Props} props */
	constructor(props) {
		super(props);

		/** @type {State} */
		this.state = {
			category: '',
			currentPageNumber: 1,
			errorMessage: null,
			isGettingData: true,
			isGettingSearchResults: false,
			isPaginated: false,
			lastSearchTerm: null,
			offerList: [],
			postalCodeDisabled: true,
			restricted: false,
			teamMembers: [],
			totalPages: null,
		};

		this._handleChange = this._handleChange.bind(this);
		this._handleSubmit = this._handleSubmit.bind(this);
		this.isInAppModule = this.props.match.url.startsWith(paths.app.root);
		this.searchMarketplace = this.searchMarketplace.bind(this);
		this.setCurrentPageNumber = this.setCurrentPageNumber.bind(this);
	}

	/** @type {Set<string>} */
	#groups = new Set();

	/** @param {number} pageNumber */
	setCurrentPageNumber(pageNumber) {
		this.setState({ currentPageNumber: pageNumber });
	}

	/**
	 * @returns {Promise<void>}
	 */
	async componentDidMount() {
		if (this.isInAppModule) {
			await this.getUserData();
			await this.#getHomeownerTeam();
		} else {
			this.setState({
				isGettingData: false,
				postalCodeDisabled: false,
			});
		}

		if (this.props.location?.state?.category) {
			this.setState({
				category: getCategoryLabel(
					this.props.location?.state?.category
				),
			});
		}
	}

	/**
	 * @param {Props} prevProps
	 * @param {State} prevState
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPageNumber !== prevState.currentPageNumber) {
			await this.searchMarketplace(
				this.state.lastSearchTerm ?? '',
				this.props.property.selectedProperty.address.postalCode
			);
		}

		if (this.state.lastSearchTerm !== prevState.lastSearchTerm) {
			this.setCurrentPageNumber(1);
		}
	}

	/**
	 * @param {MovingTask | null} nextTask
	 * @returns {Promise<void>}
	 */
	async getInitialMarketplaceOffers(nextTask) {
		if (this.props.location?.state?.category) {
			await this.searchMarketplace(
				getCategoryLabel(this.props.location.state.category),
				this.props.property.selectedProperty.address.postalCode
			);
		} else if (nextTask?.searchTerm) {
			await this.searchMarketplace(
				nextTask.searchTerm,
				this.props.property.selectedProperty.address.postalCode
			);
		}
	}

	/**
	 * @returns {Promise<void>}
	 */
	async getUserData() {
		/**
		 * @type {MovingTask | null}
		 */
		let nextTask = null;

		try {
			nextTask = await taskService.getNextMovingTask(
				this.context.accessToken
			);

			if (!this.props.property.propertyList) {
				this.setState({
					errorMessage: translate(
						'homeowner.pages.finances.view.error'
					),
				});

				return;
			}
		} catch (error) {
			captureError(error);
		}

		this.setState({ isGettingData: false }, () =>
			this.getInitialMarketplaceOffers(nextTask)
		);
	}

	/**
	 * @returns {Promise<void>}
	 */
	async #getHomeownerTeam() {
		const teamMembers =
			await this.props.homeownerService.getHomeownerTeam();

		this.setState({
			teamMembers,
		});
	}

	/**
	 * @param {string} query
	 * @param {string} postalCode
	 * @returns {Promise<void>}
	 */
	async searchMarketplace(query, postalCode) {
		this.setState({ isGettingSearchResults: true });

		try {
			const result = await this.props.marketplaceService.getVendors(
				query,
				postalCode,
				this.state.currentPageNumber
			);

			this.setState((prevState) => ({
				...prevState,
				errorMessage: null,
				isGettingSearchResults: false,
				isPaginated: result.pages > 1,
				lastSearchTerm: query,
				offerList: result.vendors,
				restricted: result.restricted,
				totalPages: result.pages,
			}));
		} catch (error) {
			captureError(error);

			this.setState({
				errorMessage: translate('global.error'),
				isGettingData: false,
				isGettingSearchResults: false,
			});
		}
	}

	/**
	 * @protected
	 * @param {{ postalCode: string }} values
	 * @returns {Promise<void>}
	 */
	async _handleSubmit(values) {
		const propertyZipCode =
			this.props.property?.selectedProperty?.address?.postalCode;

		await this.searchMarketplace(
			this.state.category,
			propertyZipCode || values.postalCode
		);
	}

	/**
	 * @protected
	 * @param {{label: string}} event
	 * @returns {void}
	 */
	_handleChange(event) {
		this.setState({
			category: event.label,
		});
	}

	/**
	 * @returns {JSX.Element}
	 */
	#renderNoContent() {
		if (!this.state.lastSearchTerm) {
			return (
				<NoContent
					message={translate(
						'global.local_pros_search.no_query.message'
					)}
					title={translate('global.local_pros_search.no_query.title')}
				/>
			);
		}

		if (!this.state.restricted) {
			return (
				<NoContent
					message={translate(
						'global.local_pros_search.no_content.message',
						this.state.lastSearchTerm
					)}
					title={translate(
						'global.local_pros_search.no_content.title'
					)}
				/>
			);
		}

		const job = occupation(this.context.agentOccupation);

		return (
			<NoContent
				message={translate(
					'global.local_pros_search.no_content_restricted.message',
					this.state.lastSearchTerm,
					job
				)}
				title={translate(
					'global.local_pros_search.no_content_restricted.title',
					job
				)}
			/>
		);
	}

	/** @returns {JSX.Element} */
	// #renderMarketPlaceSearchForm() {
	// 	return (
	// 		<section className="mt-6">
	// 			<MarketplaceSearchForm
	// 				initialCategory={this.props.location?.state?.category}
	// 				onChange={this._handleChange}
	// 				onSubmit={this._handleSubmit}
	// 				postalCodeDisabled={this.state.postalCodeDisabled}
	// 				propertyZipCode={
	// 					this.props.property?.selectedProperty?.address
	// 						?.postalCode
	// 				}
	// 			/>
	// 		</section>
	// 	);
	// }

	/**
	 * @returns {JSX.Element | JSX.Element[]}
	 */
	#renderOffers() {
		if (this.state.isGettingSearchResults) {
			return <Loading />;
		}

		const offerList = this.state.offerList.filter(Boolean);

		if (offerList.length < 1) {
			return this.#renderNoContent();
		}

		return (
			<section className="mt-6 grid gap-6 sm:grid-cols-2">
				{offerList.map((offer) => (
					<OfferCard
						description={offer.description}
						isRecommended={this.#isRecommended(
							offer.related?.id ?? null
						)}
						key={offer.id}
						logoUrl={offer.logoUrl}
						name={offer.name}
						occupation={this.props.theme.agentOccupation}
						phoneNumber={this.#getPhoneNumber(
							offer.id,
							offer.phoneNumber
						)}
						websiteUrl={offer.websiteUrl}
					/>
				))}
			</section>
		);
	}

	/**
	 * @param {string | null} id
	 * @returns {boolean}
	 */
	#isRecommended(id) {
		if (!id) {
			return false;
		}

		if (id === this.props.theme.agentId) {
			return true;
		}

		if (id === this.props.theme.agentGroup?.id) {
			return true;
		}

		return false;
	}

	/**
	 * @param {string} id
	 * @param {string} phoneNumber
	 * @returns {string}
	 */
	#getPhoneNumber(id, phoneNumber) {
		if (this.#isRecommended(id)) {
			return this.props.theme?.agentPhoneNumber ?? phoneNumber;
		}

		return phoneNumber;
	}

	render() {
		if (this.state.errorMessage) {
			return (
				<NoContent
					message={this.state.errorMessage}
					title={translate('global.error')}
				/>
			);
		}

		if (this.state.isGettingData) {
			return <Loading />;
		}

		const metadataTitle = this.props.theme?.agentName
			? `${this.props.theme.agentName}’s Marketplace`
			: 'Marketplace';

		return (
			<React.Fragment>
				<Metadata title={metadataTitle} />
				<BaseHero
					heroImageUrl={images.marketplace.header}
					title={translate('global.marketplace.title')}
				/>
				{/* this.#renderMarketPlaceSearchForm() */}
				<hr className="mt-7 border-grey-100" />
				{this.#renderOffers()}
				{this.state.isPaginated ? (
					<Pagination
						currentPageNumber={this.state.currentPageNumber}
						lastPageNumber={this.state.totalPages ?? 1}
						setCurrentPageNumber={this.setCurrentPageNumber}
					/>
				) : null}
				<footer className="mt-10">
					<p className="font-medium">
						{translate('global.local_pros_search.respa')}
					</p>
				</footer>
			</React.Fragment>
		);
	}
}

// @ts-ignore
export default withHomeownerService(withTheme(Marketplace));

Marketplace.contextType = AuthContext;
