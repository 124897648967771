import { date } from '@mooveguru/js-utilities';
import { Link } from 'react-router-dom';
import { translate } from '../App/Internationalization';
import config from '../config/local/blog';
import CopyToClipboard from './Links/CopyToClipboard';
import paths from '../config/local/paths';
import ProfileImage from './ProfileImage';
import React from 'react';
import SocialMediaLinks from './SocialMediaLinks';

export default class BlogPostExcerptCard extends React.Component {
	/**
	 * @private
	 * @param {string=} base
	 * @returns {string}
	 */
	getPath(base) {
		const pathPartList = [base ?? ''];

		pathPartList.push(this.props.agentSlug ?? '', paths.blog);

		pathPartList.push(this.props.slug);

		const path = pathPartList
			// Strips `/` chars from start and end of string
			.map((part) => part && part.replace(/^\/|\/$/g, ''))
			.filter(Boolean)
			.join('/');

		return (base ? '' : '/') + path;
	}

	/**
	 * @private
	 * @returns {string}
	 */
	getUrl() {
		/**
		 * TODO: This should be changed to reflect the "in app" URL when
		 * we have the ability to properly render blog posts server side
		 * in order to capture link previews:
		 *
		 *		return this.getPath(window.location.origin);
		 */
		return this.getExternalUrl();
	}

	/**
	 * @private
	 * @returns {string}
	 */
	getExternalUrl() {
		const url = new URL([this.props.agentSlug, 'blog', this.props.slug].join('/'), window.location.origin);

		return url.toString();
	}

	/**
	 * @returns {JSX.Element}
	 */
	/* eslint-disable max-lines-per-function -- long but not complex*/
	render() {
		const blogPath = this.getPath();
		const shareableUrl = this.getUrl();

		return (
			<section className="card card-blog card-border-light">
				<header className="card-header">
					<section className="card-header-text">
						<h2
							className="card-title"
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: this.props.title,
							}}
						/>
					</section>
				</header>

				<Link
					data-activity-click="blog_article"
					data-activity-id={this.props.title}
					to={{
						pathname: blogPath,
						state: {
							referrer: `${location.pathname}${location.search}${location.hash}`,
						},
					}}
				>
					<img
						alt={this.props.title}
						className="card-image"
						src={this.props.imageUrl}
					/>
				</Link>

				<section className="card-attribution card-body">
					<ProfileImage headshotUrl={this.props.headshotUrl}>
						<figcaption className="name">
							{translate('homeowner.pages.dashboard.blog.by')}{' '}
							{this.props.authorName.toUpperCase()}
						</figcaption>
					</ProfileImage>

					<div className="card-date">
						{date.convertDateToDateString(this.props.date)}
					</div>
				</section>

				<footer className="card-footer">
					<section className="justified-row">
						<span className="share-label">
							{translate('homeowner.pages.dashboard.blog.share')}
						</span>

						<section className="button-group">
							<SocialMediaLinks
								blogPreview={true}
								imageUrl={this.props.imageUrl}
								url={shareableUrl}
							/>

							<CopyToClipboard url={shareableUrl} />
						</section>
					</section>
				</footer>
			</section>
		);
	}
	/* eslint-enable max-lines-per-function */
}

BlogPostExcerptCard.defaultProps = {
	isAgentDashboard: false,
};
